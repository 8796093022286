var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.fileVersions,"search":_vm.search},scopedSlots:_vm._u([{key:"item.versionName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:" taskName",staticStyle:{"border":"none"}},[_c('div',{staticClass:"taskCell taskName",staticStyle:{"border":"none"}},[_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({maxWidth: '50px', minWidth: '50px', comfortZone: 0}),expression:"{maxWidth: '50px', minWidth: '50px', comfortZone: 0}"},{name:"model",rawName:"v-model",value:(item.versionName),expression:"item.versionName"}],staticClass:"taskRowInput",domProps:{"value":(item.versionName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "versionName", $event.target.value)},_vm.mixin_autoResize_resize],"change":function($event){return _vm.edited()}}})])]),(_vm.isEdited == true)?_c('a-button',{staticClass:"btn btn-sm btn-success btn-icon",staticStyle:{"margin-top":"3px","margin-right":"2px"},on:{"click":function($event){return _vm.save(item)}}},[_c('em',{staticClass:"icon ni ni-save"})]):_vm._e(),(_vm.isEdited == true)?_c('a-button',{staticClass:"btn btn-sm btn-danger btn-icon",staticStyle:{"margin-top":"3px"},on:{"click":function($event){return _vm.cancel()}}},[_c('em',{staticClass:"icon ni ni-delete"})]):_vm._e()],1)]}},{key:"item.reasonForUpdate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:" taskName",staticStyle:{"border":"none"}},[_c('div',{staticClass:"taskCell taskName",staticStyle:{"border":"none"}},[_c('input',{directives:[{name:"autowidth",rawName:"v-autowidth",value:({maxWidth: '180px', minWidth: '180px', comfortZone: 0}),expression:"{maxWidth: '180px', minWidth: '180px', comfortZone: 0}"},{name:"model",rawName:"v-model",value:(item.reasonForUpdate),expression:"item.reasonForUpdate"}],staticClass:"taskRowInput",domProps:{"value":(item.reasonForUpdate)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(item, "reasonForUpdate", $event.target.value)},_vm.mixin_autoResize_resize],"change":function($event){return _vm.editedRFU()}}})])]),(_vm.isEditedRFU == true)?_c('a-button',{staticClass:"btn btn-sm btn-success btn-icon",staticStyle:{"margin-top":"3px","margin-right":"2px"},on:{"click":function($event){return _vm.saveReasonForUpdate(item)}}},[_c('em',{staticClass:"icon ni ni-save"})]):_vm._e(),(_vm.isEditedRFU == true)?_c('a-button',{staticClass:"btn btn-sm btn-danger btn-icon",staticStyle:{"margin-top":"3px"},on:{"click":function($event){return _vm.cancelReasonForUpdate()}}},[_c('em',{staticClass:"icon ni ni-delete"})]):_vm._e()],1)]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.createdDate).format('h:mmA DD/MM/YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.downloadFileVersion(item.id)}}},[_c('em',{staticClass:"ni ni-file-download",staticStyle:{"fontSize":"20px"}})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }