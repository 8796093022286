<template>
  <div>
      <div class="nk-fmg-aside">
        <div class="nk-fmg-aside-wrap">
            <div class="nk-fmg-aside-top" data-simplebar>
                <ul v-if="folders" class="nk-fmg-menu">
                    <li><a class="btn btn-primary center" @click="openFileSelect()" style="color: white; margin: 5px;"><em class="icon ni ni-upload-cloud"></em> <span>Upload</span></a></li>
                    <li><a class="btn btn-primary center" v-on:click="openAddFolder" style="color: white; margin: 5px;"><em class="icon ni ni-folder-plus "></em> <span>Add Folder</span></a></li>
                    <li>
                        <a class="nk-fmg-menu-item" href="#" v-on:click="openMyFolder(null)">
                            <a-icon type="user" style="padding: 4px; fontSize: 17px"/>
                            <span class="nk-fmg-menu-text">My Files</span>
                        </a> 
                    </li>
                    <li>
                        <a class="nk-fmg-menu-item" href="#" v-on:click="openFoldersSharedWithMe(null)">
                            <a-icon type="folder" style="padding: 4px; fontSize: 17px"/>
                            <span class="nk-fmg-menu-text">Shared With Me</span>
                        </a> 
                        <!-- <ul>
                            <li v-for="folder in folders" v-bind:key="folder.id" >
                                <a v-on:click="openFolder(folder.id)" class="nk-fmg-menu-item">
                                    <a-icon type="folder" style="padding: 4px; fontSize: 17px"/>
                                    <span class="nk-fmg-menu-text">{{folder.name}}</span>
                                </a>
                            </li>
                        </ul> -->
                    </li>
                    <li><em v-on:click="openAddCompanyShareFolder()" class="icon ni ni-plus-c float-right" style="margin-top:10px; font-size:20px;"></em>
                        <a v-on:click="openFolder(null)" href="#" class="nk-fmg-menu-item" >
                            <a-icon type="home" style="padding: 4px; fontSize: 17px"/>
                            <span class="nk-fmg-menu-text">Company Shares</span>
                        </a>
                         <ul>
                            <li v-for="folder in folders" v-bind:key="folder.id" >
                                <a v-on:click="openFolder(folder.id)" class="nk-fmg-menu-item">
                                    <a-icon type="folder" style="padding: 4px; fontSize: 17px"/>
                                    <span class="nk-fmg-menu-text">{{folder.name}}</span>
                                </a>
                            </li>
                        </ul>
                        
                    </li>
                    <!-- <li >
                        <a v-on:click="openFolder(folder.id)" href="#" class="nk-fmg-menu-item" >
                            <a-icon type="folder" style="padding: 4px; fontSize: 17px"/>
                            <span class="nk-fmg-menu-text">{{folder.name}}</span>
                        </a>
                    </li>  -->
                    
                    
                </ul>
            </div>
            <!-- <div class="nk-fmg-aside-bottom">
                <div class="nk-fmg-status">
                    <h6 class="nk-fmg-status-title"><em class="icon ni ni-hard-drive"></em><span>Storage</span></h6>
                    <div class="progress progress-md bg-light">
                        <div class="progress-bar" data-progress="5"></div>
                    </div>
                    <div class="nk-fmg-status-info">12.47 GB of 50 GB used</div>
                    <div class="nk-fmg-status-action">
                        <a href="#" class="link link-primary link-sm">Upgrade Storage</a>
                    </div>
                </div>
                <div class="nk-fmg-switch">
                    <div class="dropup">
                        <a href="#" data-toggle="dropdown" data-offset="-10, 12" class="dropdown-toggle dropdown-indicator-unfold">
                            <div class="lead-text">Personal</div>
                            <div class="sub-text">Only you</div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <ul class="link-list-opt no-bdr">
                                <li><a href="#"><span>Team Plan</span></a></li>
                                <li><a class="active" href="#"><span>Personal</span></a></li>
                                <li class="divider"></li>
                                <li><a class="link" href="#"><span>Upgrade Plan</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <a-modal v-model="visible" title="Add Folder" @ok="addFolder">
                <a-input class="invisibox" placeholder="Folder Name" v-model="folder.name" :class="{'is-error' : $v.folder.name.$invalid }" />
            </a-modal>
        </div>
    </div><!-- .nk-fmg-aside -->
      <fileExplorer ref="file_Explorer" :folderId="folderId" :viewSharedFolders="viewSharedFolders" :myRoot="myRoot" :hasChanged="hasChanged"  @folderChanged="changedFolder" @myRootChange="myRootChange" />
  </div>
</template>

<script>
import fileExplorer from '@/components/docs/fileexplorer.vue'
import { required } from 'vuelidate/lib/validators'

export default {
    props: {
        sharedFoldersChanged: {
            type: Boolean
        }
    },
    validations: {
        folder: {
            name: {
                required
            }
        }
    },
    data: function () {
        return {
            viewSharedFolders: false,
            myRoot: false,
            folderId: null,
            folders: null,
            hasChanged: false,
            visible: false,
            folder: {
                name: null,
                parentId: null,
                userId: null
            }
        }
    },
    components: { fileExplorer },
    created() {
        this.getView()
      this.getFolders()
      if (this.sharedFoldersChanged === true) {
            this.viewSharedFolders = true
        } else {
            this.viewSharedFolders = false
        }
    },
    methods: {
        getView() {
            this.$http.get('/ViewAccess/docs')
            .then(() => {
            })
            .catch(() => {
            })
        },
        openAddCompanyShareFolder () {
            this.openFolder(null)
            this.openAddFolder()
        },
        //viewSharedFoldersChanged (val) {
        //    this.viewSharedFolders = val
        //},
        getFolders() {
            //this.Get_FoldersSharedWithMe()
            this.Get_SharedFolders()
        },
        openFileSelect() {
            this.$refs.file_Explorer.openFileSelect()
        },
        changedFolder(id){ 
            this.folderId = id
            this.getFolders()
        },
        openFolder (id) {
            this.viewSharedFolders = false
            this.myRoot = false
            this.folderId = id
            this.folderHasChanged()
        },
        openFoldersSharedWithMe () {
            this.viewSharedFolders = true
            this.myRoot = false
            this.folderId = null // this may need checking. 
            this.folderHasChanged()
        },
        openMyFolder (){
            this.viewSharedFolders = false
            this.myRoot = true
            this.folderId = null
            this.folderHasChanged()
        },
        Get_SharedFolders () { 
            this.$http.get('/docs/Get_SharedFolders')
            .then((response) => {
                this.folders = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        // Get_FoldersSharedWithMe () { 
        //     this.$http.get('/docs/Get_FoldersSharedWithMe')
        //     .then((response) => {
        //         this.folders = response.data
        //     })
        //     .catch(() => {
        //     this.$message.error('There has been an error')
        //     })
        // },
        folderHasChanged() {
            if (this.hasChanged === false) {
                this.hasChanged = true
            } else {
                this.hasChanged = false
            }
        },
        openAddFolder() {
            this.visible = true
        },
        closeAddFolder() {
            this.visible = false
        },
        addFolder() { 
             this.viewSharedFolders = false
            if (this.$v.folder.$invalid) {
                this.$message.error('Please check for any form errors.')
            } else {
            if (this.myRoot == true) {
                this.folder.myRoot = true
                this.$http.post('/docs/Add_Folder', this.folder)
                .then(() => {
                    this.$refs.file_Explorer.getFolder(null, true)
                    this.$message.success('Folder Added')
                    this.folder.name = null
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            } else {
                this.folder.parentId = this.folderId
                this.$http.post('/docs/Add_Folder', this.folder)
                .then(() => {
                    this.$refs.file_Explorer.getFolder(this.folderId)
                    this.$message.success('Folder Added')
                    this.folder.name = null
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })}
            this.visible = false
            }
        },
        myRootChange(val) {
            this.myRoot = val
        }
    }
}
</script>

<style>

</style>
