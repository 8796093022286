<template>
    <v-data-table dense :headers="headers" :items="fileVersions" :search="search">   
        <template v-slot:item.versionName="{ item }">
            <div class="row">
            <div class=" taskName" style="border: none;"><div class="taskCell taskName" style="border: none;"><input v-autowidth="{maxWidth: '50px', minWidth: '50px', comfortZone: 0}" @input="mixin_autoResize_resize" @change="edited()" class="taskRowInput" v-model="item.versionName"></div> </div>
            <a-button class="btn btn-sm btn-success btn-icon" style="margin-top: 3px; margin-right: 2px" v-if="isEdited == true" @click="save(item)"><em class="icon ni ni-save"/></a-button>
            <a-button class="btn btn-sm btn-danger btn-icon" style="margin-top: 3px;" v-if="isEdited == true" @click="cancel()"><em class="icon ni ni-delete"/></a-button></div>
        </template>
        <template v-slot:item.reasonForUpdate="{ item }">
            <div class="row">
            <div class=" taskName" style="border: none;"><div class="taskCell taskName" style="border: none;"><input v-autowidth="{maxWidth: '180px', minWidth: '180px', comfortZone: 0}" @input="mixin_autoResize_resize" @change="editedRFU()" class="taskRowInput" v-model="item.reasonForUpdate"/></div> </div>
            <a-button class="btn btn-sm btn-success btn-icon" style="margin-top: 3px; margin-right: 2px" v-if="isEditedRFU == true" @click="saveReasonForUpdate(item)"><em class="icon ni ni-save"/></a-button>
            <a-button class="btn btn-sm btn-danger btn-icon" style="margin-top: 3px;" v-if="isEditedRFU == true" @click="cancelReasonForUpdate()"><em class="icon ni ni-delete"/></a-button></div>
        </template>
        <template v-slot:item.createdDate="{ item }">
            {{ $moment(item.createdDate).format('h:mmA DD/MM/YYYY')}}
        </template> 
        <template v-slot:item.actions="{ item }">
            <button class="btn" @click="downloadFileVersion(item.id)"><em class="ni ni-file-download" style="fontSize: 20px;"></em></button>
        </template> 
    </v-data-table> 
</template>

<script>
export default {
    props: {
        fileid: Number
    },
    data() {
        return {
            headers: [
                {
                    text: 'Version',
                    align: 'start',
                    sortable: false,
                    value: 'versionName',
                    width: '20%',
                },
                { text: 'Reason For Update', value: 'reasonForUpdate', width: '35%' },
                { text: 'Created By', value: 'createdByUser', width: '20%' },
                { text: 'Created At', value: 'createdDate', width: '24%' },
                { text: 'Download', value:'actions' }
            ],
            fileVersions: [],
            search: '',
            isEdited: false,
            isEditedRFU: false,
        }
    },
    created() {
        this.getFileVersions()
    },
    methods: {
        getFileVersions() {
            this.$http.get('/docs/Get_FileVersions/' + this.fileid)
            .then((response) => {
                this.fileVersions = response.data
            })
        },
        downloadFileVersion(id) {
            this.$http.get('/docs/getFileVersion/' + id, { 'responseType': 'blob' })
            .then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]))
            var fileLink = document.createElement('a')
            fileLink.href = fileURL
            //   if (['.pdf', '.txt'].indexOf(response.headers.fileextension) === null ) {
            //       // force download of not penable in browser
            //       fileLink.setAttribute('download', response.headers.filename)
            //   }
            fileLink.setAttribute('download', response.headers.filename)
            document.body.appendChild(fileLink)
            fileLink.click()
            })
            .catch((error) => {
                if (error != null) {
                    this.$message.error('There was an error fetching this file')
                } else {
                    this.$message.error('There was an error fetching this file')
                }
            })
        },
        edited() {
            this.isEdited = true
        },
        cancel() {
            this.isEdited = false
            this.getFileVersions()
        },
        save(item){ 
            // alert(JSON.stringify(item))
            this.$http.post('/docs/Update_FileVersionName/', item)
            .then(() => {
                this.isEdited = false
                this.$message.success('Saved')
                this.getFileVersions()
            })
        },
        editedRFU() {
            this.isEditedRFU = true
        },
        cancelReasonForUpdate() {
            this.isEditedRFU = false
            this.getFileVersions()
        },
        saveReasonForUpdate(item){ 
            // alert(JSON.stringify(item))
            this.$http.post('/docs/Update_FileVersionReasonForUpdate/', item)
            .then(() => {
                this.isEditedRFU = false
                this.$message.success('Saved')
                this.getFileVersions()
            })
        }
    }
}
</script>

<style>

</style>