<template>
    <div class="nk-fmg">
        <div class="nk-fmg-body" >
            <div class="nk-fmg-body-content pt-0" style="position: relative; height: 90vh; margin: -32px; "  @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <!-- todo - move these off to their own components. -->
                <div style="width:100%; padding:30px;" v-if="imgPreview">
                    <div class=" float-right m-2">
                            <button v-on:click="openFile(previewFileId)" class="btn btn-sm btn-dark mr-2">
                                        <em class="icon ni ni-download"></em> 
                                        <span>Download</span>
                                </button>
                              <button v-on:click="closePreview()" class="btn btn-sm btn-light">
                                        <em class="icon ni ni-plus"></em> 
                                        <span>Close</span>
                                </button>
                        </div>
                    <img :src="imgPreview" />
                </div>
     
                    <div  v-show="showPreviewIframe && iframePreviewHasLoaded">
                        <div class=" float-right m-2">
                            <button v-on:click="openFile(previewFileId)" class="btn btn-sm btn-dark mr-2">
                                        <em class="icon ni ni-download"></em> 
                                        <span>Download</span>
                                </button>
                              <button v-on:click="closePreview()" class="btn btn-sm btn-light">
                                        <em class="icon ni ni-close"></em> 
                                        <span>Close</span>
                                </button>
                        </div>
                        <iframe  id="previewIFrame" ref="previewIFrame" @load="previewIframeLoaded" :src="iframeURI" style="width:100%; height:100vh;" frameborder="0"></iframe>
                        
                    </div> 
                <!-- todo - move these off to their own components. -->
                <div class="nk-fmg-search">
                    <div class="col-5">
                        <a-input type="text" v-model="searchFilter" class="mb-2 border-transparent" placeholder="Search Current Directory">
                            <a-icon slot="prefix" type="search" />
                        </a-input>
                    </div>  
                    <div class="col-2">

                    </div>
                    <div class="col-5">
                        <a-input type="text" v-model="globalSearch" v-on:input="debounceInput" class="mb-2 border-transparent form-focus-none float-right" placeholder="Search Globally">
                            <a-icon slot="prefix" type="global" />
                        </a-input>
                    </div>
                </div>
                
                <!-- file Input Hidden -->
                <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" 
                    style="display:none" class="w-px h-px opacity-0 overflow-hidden absolute" @change="onChange" ref="fileUpload"  />
                <!-- file Input Hidden -->
                <!-- <div class="nk-block-head nk-block-head-sm">
                    <div class="nk-block-between position-relative">
                        <div class="search-wrap px-2 d-lg-none" data-search="search">
                            <div class="search-content">
                                <a class="search-back btn btn-icon toggle-search" data-target="search"><em class="icon ni ni-arrow-left"></em></a>
                                <input v-model="searchFilter" class="form-control border-transparent form-focus-none" placeholder="Search by user or message">
                                <button class="search-submit btn btn-icon"><em class="icon ni ni-search"></em></button>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="nk-fmg-quick-list nk-block">
                    <div class="nk-block-head-xs">
                        <div class="nk-block-between g-2">
                            <div class="nk-block-head-content">
                                <a-breadcrumb separator=">" style="fontSize:16px;">
                                    <a-breadcrumb-item v-for="f in folderBreadcrumbs" :key="f.id"> 
                                        <a-button class="btn" style="border:none;" @click="getBreadCrumbFolder(f.id)">{{ f.folderName}}</a-button>
                                    </a-breadcrumb-item>
                                </a-breadcrumb>
                            </div>
                            <div class="nk-block-head-content">
                                <ul class="nk-block-tools g-3 nav">
                                    <li><a data-toggle="tab" v-on:click="viewType = 'grid'" :class="{active : viewType === 'grid'}" href="#file-grid-view" class="nk-switch-icon"><em class="icon ni ni-view-grid3-wd"></em></a></li>
                                    <li><a data-toggle="tab" v-on:click="viewType = 'group'" :class="{active : viewType === 'group'}" href="#file-group-view" class="nk-switch-icon"><em class="icon ni ni-view-group-wd"></em></a></li>
                                    <li><a data-toggle="tab" v-on:click="viewType = 'list'" :class="{active : viewType === 'list'}" href="#file-list-view" class="nk-switch-icon"><em class="icon ni ni-view-row-wd"></em></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-if="filesandfolders.length" class="toggle-expand-content expanded">
                        <div :class="{'nk-files-view-grid' : viewType === 'grid',
                         'nk-files-view-group' : viewType === 'group',
                        'nk-files-view-list' : viewType === 'list'                 
                        }" class="nk-files">

                            <div v-if="viewType === 'list'" class="nk-files-head">
                                <div class="nk-file-item">
                                    <div class="nk-file-info">
                                        <div class="tb-head dropdown-toggle dropdown-indicator-caret" data-toggle="dropdown">Name</div>
                                        <div class="dropdown-menu dropdown-menu-xs">
                                            <ul class="link-list-opt no-bdr">
                                                <li class="opt-head"><span>ORDER BY</span></li>
                                                <li><a href="#"><span>Descending</span></a></li>
                                                <li><a href="#"><span>Ascending</span></a></li>
                                            </ul>
                                        </div>
                                        <div class="tb-head"></div>
                                    </div>
                                    <div class="nk-file-meta">
                                        <div class="dropdown">
                                            <div class="tb-head dropdown-toggle dropdown-indicator-down" data-toggle="dropdown">Last Opened</div>
                                            <div class="dropdown-menu dropdown-menu-xs">
                                                <ul class="link-list-opt ui-colored no-bdr">
                                                    <li class="opt-head"><span>ORDER BY</span></li>
                                                    <li><a class="active" href="#"><span>Descending</span></a></li>
                                                    <li><a href="#"><span>Ascending</span></a></li>
                                                    <li class="divider"></li>
                                                    <li class="opt-head"><span>SHOW</span></li>
                                                    <li><a class="active" href="#"><span>Last Updated</span></a></li>
                                                    <li><a href="#"><span>Name</span></a></li>
                                                    <li><a href="#"><span>Size</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nk-file-members">
                                        <div class="tb-head">Version</div>
                                    </div>
                                    <div class="nk-file-members">
                                        <div class="tb-head">Owner</div>
                                    </div>
                                    <div class="nk-file-members">
                                        <div class="tb-head">Preview</div>
                                    </div>
                                    <div class="nk-file-actions">
                                        <div class="dropdown">
                                            <a href="" class="dropdown-toggle btn btn-sm btn-icon btn-trigger" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <ul class="link-list-opt no-bdr">
                                                    <li><a href="#file-share" data-toggle="modal"><em class="icon ni ni-share"></em><span>Share</span></a></li>
                                                    <li><a href="#file-copy" data-toggle="modal"><em class="icon ni ni-copy"></em><span>Copy</span></a></li>
                                                    <li><a href="#file-move" data-toggle="modal"><em class="icon ni ni-forward-arrow"></em><span>Move</span></a></li>
                                                    <li><a href="#" class="file-dl-toast"><em class="icon ni ni-download"></em><span>Download</span></a></li>
                                                    <li><a href="#"><em class="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="nk-files-list">
                                <div style="postion: relative;" class="nk-file-item  nk-file"  v-bind:key="f.reference" v-for="f in filteredFiles">
                                   
                                    <div v-if="f.isFile && (viewType === 'grid')" style=" position:absolute; bottom:10px; right:10px;">
                                        <div>
                                            <button v-on:click="previewFile(f)"  style="width:30px; padding:10px 10px 10px 6px; height:30px; z-index:100;" class="btn btn-dim btn-dark"><em  style=" font-size:15px;" class="text-success icon ni ni-eye"></em></button>
                                        </div>
                                    </div>
                                    <div v-if="f.isFile && viewType === 'group'"  style=" width:10px; position:absolute; bottom:-4px; right:-14px;">
                                        <div >
                                            <button v-on:click="previewFile(f)" style="width:20px; padding:2px 0px 2px 4px; height:20px; z-index:100;" class="btn btn-dim btn-dark"><em  style=" font-size:10px;" class="text-success icon ni ni-eye"></em></button>
                                        </div>
                                    </div>
                                    <div class="nk-file-info">
                                        <div class="nk-file-title">
                                            <!-- <div class="custom-control custom-control-sm custom-checkbox notext">
                                                <input type="checkbox" class="custom-control-input" id="file-check-n1">
                                                <label class="custom-control-label" for="file-check-n1"></label>
                                            </div> -->
                                            <div class="nk-file-icon">
                                                <span class="nk-file-icon-type">
                                                    <img @click="getFileOrFolder(f)" style="cursor:pointer;" class="img-time-matters" :src="require(`@/assets/images/icons/${f.imageName}`)">
                                                </span>
                                            </div>
                                            <div class="nk-file-name">
                                                <div class="nk-file-name-text">
                                                    <a @click="getFileOrFolder(f)" style="cursor:pointer;" class="title">{{f.name}}{{f.fileExtension}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="nk-file-meta">
                                        <div class="tb-lead">{{$moment(f.createdDate).fromNow()}}</div>
                                    </div>
                                    <div class="nk-file-meta">
                                        <div class="tb-lead" v-if="f.isFile">Version: {{f.versionName}}</div>
                                    </div>
                                    <div class="nk-file-members">
                                        <div class="tb-lead">{{f.createdByUser}}</div>
                                    </div>
                                     <div class="nk-file-members" v-if="  (viewType === 'list')"  style="">
                                        <div v-if="f.isFile">
                                            <button  v-on:click="previewFile(f)" style="width:30px; padding:10px 10px 10px 6px; height:30px; z-index:100;" class="btn btn-dim btn-dark"><em  style=" font-size:15px;" class="text-success icon ni ni-eye"></em></button>
                                        </div>
                                        <div v-if="!f.isFile">

                                        </div>
                                    </div>
                                    <div class="nk-file-actions">
                                       <a-dropdown>
                                            <div class="dropdown">
                                                <a class="text-soft dropdown-toggle btn btn-icon btn-trigger" >
                                                <em class="icon ni ni-more-h"></em></a>
                                            </div>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a v-on:click="deleteFileFolder(f)"><i class="bg-danger-dim ni ni-trash mr-1"></i> Delete</a>
                                                </a-menu-item>
                                                <a-menu-item v-if="f.isFile && $hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655')">
                                                    <a v-on:click="addNewFileVersion(f.id)"><i class="bg-success-dim ni ni-edit mr-1"></i> Upload New Version</a>
                                                </a-menu-item>
                                                <a-menu-item v-if="f.isFile && $hasRole('6BAB1BED-A580-4B53-8088-023E65D972C3') || $hasRole('7092BD1E-10BC-4E85-A1F1-F18308843655')">
                                                    <a v-on:click="viewFileVersions(f.id)"><i class="bg-success-dim ni ni-list mr-1"></i> File Versions</a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a @click="viewUserPermissions(f)"><i class="ni ni-user mr-1"></i> Set User Permissions</a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a @click="viewTeamPermissions(f)"><i class="ni ni-network mr-1"></i> Set Team Permissions</a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                </div>
                                <!-- <div style="position:relative;" class="nk-file-item nk-file" v-bind:key="f.reference" v-for="f in filteredFiles">

                                    <div class="nk-file-info">
                                        <a class="nk-file-link" @click="getFileOrFolder(f)">
                                            <div class="nk-file-title">
                                                <div class="nk-file-icon">
                                                    <span class="nk-file-icon-type">
                                                         <img class="img-time-matters" :src="require(`@/assets/images/icons/${f.imageName}`)">
                                                    </span>
                                                </div>
                                                <div class="nk-file-name">
                                                    <div class="nk-file-name-text">
                                                        <span class="title">{{f.name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div  class="nk-file-actions hideable">
                                        <a-dropdown>
                                            <div class="dropdown">
                                                <a class="text-soft dropdown-toggle btn btn-icon btn-trigger" >
                                                <em class="icon ni ni-more-h"></em></a>
                                            </div>
                                            <a-menu slot="overlay">
                                                <a-menu-item >
                                                    <a v-on:click="deleteFileFolder(f)"><i class="bg-danger-dim ni ni-trash mr-1"></i> Delete</a>
                                                </a-menu-item>
                                                <a-menu-item v-if="f.isFile">
                                                    <a v-on:click="addNewFileVersion(f.id)"><i class="bg-success-dim ni ni-edit mr-1"></i> Upload New Version</a>
                                                </a-menu-item>
                                                <a-menu-item v-if="f.isFile">
                                                    <a v-on:click="viewFileVersions(f.id)"><i class="bg-success-dim ni ni-list mr-1"></i> File Versions</a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                    <div v-if="f.isFile" class="fileExpversonBox" >
                                        v {{f.versionId}}.0
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="col-xxl">
                            <div class="preview-icon-box card">
                                <span class="nk-file-icon-type" style="width:250px; height:250px; opacity:50%; margin-top:50px">
                                    <img class="img-time-matters" :src="require(`@/assets/images/icons/support-general.svg`)">
                                </span>
                                <span class="preview-icon-name" style="margin-top:20px; fontSize:15px;">No Files Available</span>
                            </div><!-- .preview-icon-box -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <fileUpload :fileVersionSelectedId="fileVersionSelectedId" ref="fUpload" @uploaded="openNewFileVersion" v-on:uploadFinished="fileUploadFinished" :isMyRoot="currentlyInMyRoot" :files="filelist" :folderId="intMyFolderId" ></fileUpload>
        <a-drawer v-if="showVersionDetails" title="File Versions" :placement="'right'" :closable="true" :visible="showVersionDetails" @close="showHideVersions" :width="850">
            <fileversions :fileid="selectedFileId"></fileversions>
        </a-drawer>

         <!-- USER PERMISSIONS MODAL -->
        <a-modal v-model="showUserPermissions" title="User Permissions" :width="modalWidth" :afterClose="closeUserPermissions">
            <div class="row">
                <div class="col-3">
                    
                </div>
                <div class="col-5" v-if="permission.isFile">
                    <label class="form-label">File Owner:</label>
                    <strong style="padding-left: 10px">{{ permission.fileCreatedByUser }}</strong>
                </div>
                <div class="col-5" v-if="permission.isFolder">
                    <label class="form-label">Folder Owner :</label>
                    <strong style="padding-left: 10px">{{ permission.folderCreatedByUser }}</strong>
                </div>
                <div class="col-2">
                    <a-button class="btn btn-danger" @click="takeOwnership()">Take Ownership</a-button>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                </div>
                <div class="col-5">
                    <label class="form-label">User Name</label>
                    <a-select show-search option-filter-prop="children" :filter-option="usersFilter" v-model="permission.userId" style="width: 100%;">
                        <a-select-option v-for="u in users" :key="u.id">
                            {{ u.userName }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-12">
                    <a-button class="btn-success float-right" @click="saveUserPermission()"><em class="icon ni ni-save" style="padding-right: 5px;"></em> Add User</a-button>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="row">
                <div class="col-12">
                    <h6>Current Permissions</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <vue-good-table
                        v-if="permittedUsers"
                        styleClass="vgt-table condensed"
                        :columns="usersColumns"
                        :rows="permittedUsers">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'createdDate'">
                                {{ $moment(props.row.createdDate).format('H:mm a - DD/MM/YYYY')}}
                            </div>
                            <div v-else-if="props.column.field == 'actions'">
                                <a-dropdown>
                                    <a><em class="icon ni ni-more-h" style="fontSize: 20px"></em></a>
                                    <a-menu slot="overlay">
                                        <a-menu-item style="color: red;" @click="deleteUserPermission(props)"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn-dark btn" @click="showUserPermissions = false">Close</a-button>
            </template>
        </a-modal>

        <!-- TEAMS PERMISSIONS MODAL -->
        <a-modal v-model="showTeamPermissions" title="Team Permissions" :width="modalWidth" :afterClose="closeUserPermissions">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-5">
                    <label class="form-label">Team Name</label>
                    <a-select show-search v-model="permission.teamId" option-filter-prop="children" :filter-option="teamsFilter" style="width: 100%;">
                        <a-select-option v-for="u in teams" :key="u.id">
                            {{ u.teamName }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-12">
                    <a-button class="btn-success float-right" @click="saveUserPermission()"><em class="icon ni ni-save" style="padding-right: 5px;"></em> Add Team</a-button>
                </div>
            </div>
            <hr class="preview-hr">
            <div class="row">
                <div class="col-12">
                    <h6>Current Permissions</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <vue-good-table
                        v-if="permittedUsers"
                        styleClass="vgt-table condensed"
                        :columns="usersColumns"
                        :rows="permittedUsers">
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'createdDate'">
                                {{ $moment(props.row.createdDate).format('H:mm a - DD/MM/YYYY')}}
                            </div>
                            <div v-else-if="props.column.field == 'actions'">
                                <a-dropdown>
                                    <a><em class="icon ni ni-more-h" style="fontSize: 20px"></em></a>
                                    <a-menu slot="overlay">
                                        <a-menu-item style="color: red;" @click="deleteUserPermission(props)"><em class="icon ni ni-cross-circle"/> Delete</a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </div>
            <template slot="footer">
                <a-button class="btn-dark btn" @click="showTeamPermissions = false">Close</a-button>
            </template>
        </a-modal>

    </div>
</template>

<script>
import fileUpload from '@/components/docs/fileupload'
import fileversions from '@/components/docs/fileversions'
import _ from 'lodash'
export default {
    components: {
        fileUpload,
        fileversions,
    },
    props: {
        folderId: {
        type: Number,
        }, 
        myRoot: {
            type: Boolean
        },
        viewSharedFolders: {
            type: Boolean
        },
        hasChanged: {
            type: Boolean
        }
    },
    data () {
        return { 
            iframePreviewHasLoaded: false,
            previewFileId: null,
            imgPreview: null,
            showPreviewIframe: false,
            iframeURI: null,
            folderBreadcrumbs: null,
            timeout: null,
            globalSearch: '',
            usersFilter: '',
            teamsFilter: '',
            viewType: 'grid',
            fileVersionSelectedId: null,
            selectedFileId: null,
            showVersionDetails: false,
            currentlyInMyRoot: null,
            filelist: [], // Store our uploaded files
            intMyFolderId: null,
            intMyRoot: null,
            displaySideMenu: false,
            filesandfolders: [],
            searchFilter: '',
            showUserPermissions: false,
            showTeamPermissions: false,
            currentFolder: {},
            modalWidth: '55%',
            users: [],
            teams: [],
            permission: {
                isFile: false,
                isFolder: false,
            },
            permittedUsers: [],
            usersColumns: [
                { label: 'User Name', field: 'userName' },
                { label: 'Team Name', field: 'teamName'},
                { label: 'Created Date', field: 'createdDate' },
                { label: 'Created By User', field: 'createdByUser'},
                { label: 'Actions', field: 'actions', thClass: 'text-center', tdClass: 'text-center'}
            ]
        }
    },
    watch: {
        hasChanged: function () { 
            this.intMyFolderId = this.folderId
            this.intMyRoot = this.myRoot
            this.currentFolder.id = this.intMyFolderId
            this.getFolder()
        },
        currentlyInMyRoot: function () {
            this.$emit('myRootChange', this.currentlyInMyRoot)
        },
    },
    created() {
        this.getFolder()
        this.getLists()
    },
    computed: {
        filteredFiles: function() {
            if (this.searchFilter != '') {
                return this.filesandfolders.filter((f) => {  return f.name.toLowerCase().match(this.searchFilter.toLowerCase()) })
            }
            return this.filesandfolders 
        }
    },
    methods: {
        openNewFileVersion(newId) {
            // alert(newId)
            this.selectedFileId = newId
            if (this.fileVersionSelectedId != null){
                this.showVersionDetails = true
            }
        },
        previewIframeLoaded () {
            if (this.showPreviewIframe) {
             this.iframePreviewHasLoaded = true 
            }
        },
        closePreview () {
            this.showPreviewIframe = false
            this.iframeURI = null
            this.imgPreview = false
        },
        deleteFileFolder (f) { 
            if (f.isFile) { 
                this.$http.post('/docs/Delete_File/', f )
                .then(() => {
                    this.$message.success('File Deleted')
                    this.getFolder(this.folderId,this.myRoot)
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            } else {
                this.$http.post('/docs/Delete_Folder/', f )
                .then(() => {
                    this.$message.success('Folder Deleted')
                    this.getFolder(this.folderId,this.myRoot)
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            }
        },
        openFileSelect () {
            this.$refs.fileUpload.click()
        },
        showHideVersions(){
            if (this.showVersionDetails) {
                this.showVersionDetails  = false
            } else {
                this.showVersionDetails  = true
            }
        },
        viewFileVersions(id) {
            this.selectedFileId = id
            this.showVersionDetails  = true
        },
        viewUserPermissions(f) {
            if (f.isFile) {
                this.permission.isFile = true
                this.permission.fileId = f.id
                this.permission.fileCreatedByUser = f.createdByUser
                this.getFilePermissions(f.id)
            } else {
                this.permission.isFolder = true
                this.permission.folderId = f.id
                this.permission.folderCreatedByUser = f.createdByUser
                this.getFolderPermissions(f.id)
            }
            this.showUserPermissions = true
        },
        viewTeamPermissions(f) {
            if (f.isFile) {
                this.permission.isFile = true
                this.permission.fileId = f.id
                this.getFilePermissions(f.id)
            } else {
                this.permission.isFolder = true
                this.permission.folderId = f.id
                this.getFolderPermissions(f.id)
            }
            this.showTeamPermissions = true
        },
        closeUserPermissions(){
            this.permission.isFile = false
            this.permission.isFolder = false
        },
        getFilePermissions(f) {
            this.$http.get('/docs/Get_File_Permissions/' + f)
            .then((response) => {
                this.permittedUsers = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getFolderPermissions(f) {
            this.$http.get('/docs/Get_Folder_Permissions/' + f)
            .then((response) => {
                this.permittedUsers = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        getFolderBreadcrumbs(id) {
            if (id != null) {
                this.$http.get('/docs/Get_Folder_Breadcrumbs/' + id)
                .then((response) => {
                    this.folderBreadcrumbs = response.data
                })
                .catch(() => {
                    this.$message.error('There has been an error')
                })
            } else {
                this.folderBreadcrumbs = null
            }
        },
        takeOwnership() {
            this.$http.post('/docs/Take_Docs_Ownership', this.permission)
            .then(() => {
                this.$message.success('Ownership Taken')
                if (this.permission.isFile) {
                    this.$emit('folderChanged')
                    this.showUserPermissions = false
                } else {
                    this.showUserPermissions = false
                    this.$emit('folderChanged')
                }
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        },
        saveUserPermission() {
            this.$http.post('/docs/Add_Docs_Permissions', this.permission)
            .then(() => {
                this.$message.success('User Permission Granted')
                if (this.permission.isFile) {
                    this.getFilePermissions(this.permission.fileId)
                    this.$emit('folderChanged')
                } else {
                    this.getFolderPermissions(this.permission.folderId)
                    this.$emit('folderChanged')
                }
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        deleteUserPermission(props){
            this.$http.post('/docs/Delete_Docs_Permissions', props.row)
            .then(() => {
                this.$message.success('User Permission Removed')
                if (this.permission.isFile) {
                    this.getFilePermissions(this.permission.fileId)
                    this.$emit('folderChanged')
                } else {
                    this.getFolderPermissions(this.permission.folderId)
                    this.$emit('folderChanged')
                }
            })
        },
        getLists() {
            this.$http.get('/users/Get_Users')
            .then((response) => {
                this.users = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
            this.$http.get('/teams/get_Teams')
            .then((response) => {
            this.teams = response.data.teams
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        checkIfIframePreviewLoads () {
            // wait for 3 seconds and check - if not reload..
            let mythis  = this
            setTimeout(function() {
               if (!mythis.iframePreviewHasLoaded) {
                   if (mythis.iframeURI != null && mythis.showPreviewIframe)
                   {
                        document.getElementById('previewIFrame').src = document.getElementById('previewIFrame').src
                   }
               }
            }, 3000);
        },
        previewInGdocs(item) {
            this.$http.get('/docs/Add_PublicAccessFileLink/' + item.id)
            .then((response) => { 
                this.iframePreviewHasLoaded = false
                this.checkIfIframePreviewLoads()
                this.imgPreview = null
                this.iframeURI = 'https://docs.google.com/gview?url=' + response.data + '&embedded=true'
                this.showPreviewIframe = true
                
                
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })  
        },
        previewInBrowser(item) {
           this.$http.get('/docs/Add_PublicAccessFileLink/' + item.id)
            .then((response) => { 
                this.iframeURI = null
                this.iframePreviewHasLoaded = false
                this.showPreviewIframe = false
                this.imgPreview  = response.data
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })  
        },
        // getShareableLink (item) {  
        //     this.$http.get('/docs/Add_PublicAccessFileLink/' + item.id)
        //         .then((response) => { 
        //             response.data
        //         })
        //         .catch(() => {
        //             this.$message.error('There has been an error')
        //         })  
        // },
        previewFile(item) {
            this.viewSharedFolders = false
            this.previewFileId = item.id
           if (item.isFile) {
                // get private URL. for doc viewer
                if (    item.fileExtension == '.docx' || 
                        item.fileExtension == '.doc' || 
                        item.fileExtension == '.xlsx' || 
                        item.fileExtension == '.pdf' || 
                        item.fileExtension == '.ppt' || 
                        item.fileExtension == '.pptx' || 
                        item.fileExtension == '.csv'    ||
                        item.fileExtension == '.xls' //|| 
                        //item.fileExtension == '.pdf'
                    )
                    {
                        this.previewInGdocs(item)
                    } 
                else if (
                            item.fileExtension == '.jpg' ||
                            item.fileExtension == '.pdf' ||
                            item.fileExtension == '.png' 
                        )
                    {
                        this.previewInBrowser(item)
                    }
                
           } else { 
               this.getFolder(item.id)
           }
        },
        getFileOrFolder(item) { 
            this.viewSharedFolders = false
           if (item.isFile) {
               this.openFile(item.id)
           } else {
               this.getFolder(item.id)
           }
        },
        fileUploadFinished (folderLocation) {
          this.getFolder(folderLocation.id,folderLocation.myRoot)
        },
        getBreadCrumbFolder(id) {
                this.currentlyInMyRoot  = false
                let uri = '/docs/Get_Folder/'
                if (id != null) {
                    this.currentFolder.id = id
                    uri  = '/docs/Get_Folder/'
                }  
                this.$emit('folderChanged', id)
                this.$http.post(uri, this.currentFolder)
                .then((response) => {
                    this.intMyFolderId = this.currentFolder.id
                    this.intMyRoot = false
                    this.filesandfolders = response.data
                    if (this.intMyFolderId != null) {
                        this.getFolderBreadcrumbs(this.currentFolder.id)
                    }
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
        },
        getFolder(id,myRoot = this.intMyRoot) { 
             this.folderBreadcrumbs = null
            if (this.viewSharedFolders === true){ 
                this.$http.get('/docs/Get_FilesAndFoldersSharedWithMe/')
                .then((response) => {
                    //this.intMyFolderId = id
                    //this.intMyRoot = false
                    this.filesandfolders = response.data
                    //this.getFolderBreadcrumbs()
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            }
            else if (!myRoot) {
                this.currentlyInMyRoot  = false
                let uri = '/docs/Get_Folder/'
                if (id != null) {
                    this.currentFolder.id = id
                    uri  = '/docs/Get_Folder/'
                }  
                this.$emit('folderChanged', this.currentFolder.id)
                this.$http.post(uri, this.currentFolder)
                .then((response) => {
                    this.intMyFolderId = this.currentFolder.id
                    this.intMyRoot = false
                    this.filesandfolders = response.data
                    if (this.intMyFolderId != null) {
                        this.getFolderBreadcrumbs(this.currentFolder.id)
                    }
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            } else {
                this.currentlyInMyRoot  = true
                this.$http.get('/docs/Get_My_Folder/')
                .then((response) => {
                    this.intMyFolderId = id
                    this.intMyRoot = false
                    this.filesandfolders = response.data
                    this.getFolderBreadcrumbs()
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            }
        },
        debounceInput: _.debounce(function () {
            this.getGlobalSearchFolder()
        }, 800),
        getGlobalSearchFolder(){
            if (this.globalSearch != '') {
                this.currentFolder.globalSearch = this.globalSearch
                this.$http.post('/docs/Get_GlobalSearch_Folder', this.currentFolder)
                .then((response) => {
                    this.filesandfolders = response.data
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            } else {
                this.getFolder()
            }
        },
        showSideMenu(){
            if (this.displaySideMenu === true) {
            this.displaySideMenu = false 
            } else {
            this.displaySideMenu = true
            }
        },
        addNewFileVersion(id) {
            // this is to force the watch on the fileuploader
            this.fileVersionSelectedId  = 9999999999999999999999
            this.$nextTick(() => { 
                this.fileVersionSelectedId = id
                this.openFileSelect()
            }) 
        },
        onChange() {
        this.filelist = [...this.$refs.fileUpload.files];
        },
        remove(i) {
        this.filelist.splice(i, 1);
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            //if (!event.currentTarget.classList.contains('bg-green-300')) {
            //    event.currentTarget.classList.remove('bg-gray-100');
            //    event.currentTarget.classList.add('bg-green-300');
            //}
        },
        dragleave(event) {
        // Clean up
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
        },
        drop(event) {
        event.preventDefault();
        this.$refs.fileUpload.files = event.dataTransfer.files;
        this.onChange(); // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100');
        event.currentTarget.classList.remove('bg-green-300');
        }, 
        openFile(id) {
        this.$http.get('/docs/getFile/' + id, { 'responseType': 'blob' })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
        //   if (['.pdf', '.txt'].indexOf(response.headers.fileextension) === null ) {
        //       // force download of not penable in browser
        //       fileLink.setAttribute('download', response.headers.filename)
        //   }
           fileLink.setAttribute('download', response.headers.filename)
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch((error) => {
            if (error != null) {
                this.$message.error('There was an error fetching this file')
            } else {
                this.$message.error('There was an error fetching this file')
            }
        })
        },
    }
}
</script>

<style>
.fileExpversonBox {
    bottom: 10px;
    right: 10px;
    position: absolute !important;
    font-size: 10px;
    font-weight: 600;
    color: #101924;
}

</style>